<div class="container-fluid">
  <div class="card general-widget">
    <div class="card-header no-border padding-15">

      <div class="row">
        <div class="col-12">
          <h4 class="m-l-50 m-t-10">
            Issues - Home
          </h4>
        </div>
      </div>
    </div>
    <div class="card-body m-t-20 padding-15">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 xl-100 box-col-12">
          <div class="card">
            <div class="card-header p-20 no-border">
              <h5 class="m-l-50">Provisional Issues</h5>

            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-primary">
                          <app-feather-icons [icon]="'file'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0 ">Total Issues</span>
                          <h4 class="mb-0 counter">{{provisionaLIssue?.total_count}}</h4>
                          <i class="icon-bg" data-feather="file"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-warning">
                          <app-feather-icons [icon]="'award'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0">Payment Received Issues</span>
                          <h4 class="mb-0 counter">{{provisionaLIssue?.payment_received_count}}</h4>
                          <i class="icon-bg" data-feather="award"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-teal">
                          <div>
                            <app-feather-icons [icon]="'folder'"></app-feather-icons>
                          </div>
                        </div>
                        <div class="media-body"><span class="m-0">Document Delivered Issues</span>
                          <h4 class="mb-0 counter">{{provisionaLIssue?.document_delivered_count}}</h4>
                          <i class="icon-bg" data-feather="folder"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 xl-100 box-col-12">
          <div class="card">
            <div class="card-header p-20 no-border">
              <h5 class="m-l-50">Marksheet Issues</h5>

            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-primary">
                          <app-feather-icons [icon]="'file'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0 ">Total Issues</span>
                          <h4 class="mb-0 counter">{{markSheetIssue?.total_count}}</h4>
                          <i class="icon-bg" data-feather="file"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-warning">
                          <app-feather-icons [icon]="'award'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0">Payment Received Issues</span>
                          <h4 class="mb-0 counter">{{markSheetIssue?.payment_received_count}}</h4>
                          <i class="icon-bg" data-feather="award"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-teal">
                          <div>
                            <app-feather-icons [icon]="'folder'"></app-feather-icons>
                          </div>
                        </div>
                        <div class="media-body"><span class="m-0">Document Delivered Issues</span>
                          <h4 class="mb-0 counter">{{markSheetIssue?.document_delivered_count}}</h4>
                          <i class="icon-bg" data-feather="folder"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 xl-100 box-col-12">
          <div class="card">
            <div class="card-header p-20 no-border">
              <h5 class="m-l-50">Transcript Issues</h5>

            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-primary">
                          <app-feather-icons [icon]="'file'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0 ">Total Issues</span>
                          <h4 class="mb-0 counter">{{transcriptIssue?.total_count}}</h4>
                          <i class="icon-bg" data-feather="file"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-warning">
                          <app-feather-icons [icon]="'award'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0">Payment Received Issues</span>
                          <h4 class="mb-0 counter">{{transcriptIssue?.payment_received_count}}</h4>
                          <i class="icon-bg" data-feather="award"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-teal">
                          <div>
                            <app-feather-icons [icon]="'folder'"></app-feather-icons>
                          </div>
                        </div>
                        <div class="media-body"><span class="m-0">Document Delivered Issues</span>
                          <h4 class="mb-0 counter">{{transcriptIssue?.document_delivered_count}}</h4>
                          <i class="icon-bg" data-feather="folder"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 xl-100 box-col-12">
          <div class="card">
            <div class="card-header p-20 no-border">
              <h5 class="m-l-50">Revaluation Issues</h5>

            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-primary">
                          <app-feather-icons [icon]="'file'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0 ">Total Issues</span>
                          <h4 class="mb-0 counter">{{revaluationIssue?.total_count}}</h4>
                          <i class="icon-bg" data-feather="file"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-warning">
                          <app-feather-icons [icon]="'award'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0">Payment Received Issues</span>
                          <h4 class="mb-0 counter">{{revaluationIssue?.payment_received_count}}</h4>
                          <i class="icon-bg" data-feather="award"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-teal">
                          <div>
                            <app-feather-icons [icon]="'folder'"></app-feather-icons>
                          </div>
                        </div>
                        <div class="media-body"><span class="m-0">Document Delivered Issues</span>
                          <h4 class="mb-0 counter">{{revaluationIssue?.document_delivered_count}}</h4>
                          <i class="icon-bg" data-feather="folder"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 xl-100 box-col-12">
          <div class="card">
            <div class="card-header p-20 no-border">
              <h5 class="m-l-50">Migration Issues</h5>

            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-primary">
                          <app-feather-icons [icon]="'file'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0 ">Total Issues</span>
                          <h4 class="mb-0 counter">{{migrationIssue?.total_count}}</h4>
                          <i class="icon-bg" data-feather="file"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-warning">
                          <app-feather-icons [icon]="'award'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0">Payment Received Issues</span>
                          <h4 class="mb-0 counter">{{migrationIssue?.payment_received_count}}</h4>
                          <i class="icon-bg" data-feather="award"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-teal">
                          <div>
                            <app-feather-icons [icon]="'folder'"></app-feather-icons>
                          </div>
                        </div>
                        <div class="media-body"><span class="m-0">Document Delivered Issues</span>
                          <h4 class="mb-0 counter">{{migrationIssue?.document_delivered_count}}</h4>
                          <i class="icon-bg" data-feather="folder"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 xl-100 box-col-12">
          <div class="card">
            <div class="card-header p-20 no-border">
              <h5 class="m-l-50">Original Degree Issues</h5>

            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-primary">
                          <app-feather-icons [icon]="'file'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0 ">Total Issues</span>
                          <h4 class="mb-0 counter">{{originalDegreeIssue?.total_count}}</h4>
                          <i class="icon-bg" data-feather="file"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-warning">
                          <app-feather-icons [icon]="'award'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0">Payment Received Issues</span>
                          <h4 class="mb-0 counter">{{originalDegreeIssue?.payment_received_count}}</h4>
                          <i class="icon-bg" data-feather="award"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-teal">
                          <div>
                            <app-feather-icons [icon]="'folder'"></app-feather-icons>
                          </div>
                        </div>
                        <div class="media-body"><span class="m-0">Document Delivered Issues</span>
                          <h4 class="mb-0 counter">{{originalDegreeIssue?.document_delivered_count}}</h4>
                          <i class="icon-bg" data-feather="folder"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 xl-100 box-col-12">
          <div class="card">
            <div class="card-header p-20 no-border">
              <h5 class="m-l-50">Other Issues</h5>

            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-primary">
                          <app-feather-icons [icon]="'file'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0 ">Total Issues</span>
                          <h4 class="mb-0 counter">{{otherIssue?.total_count}}</h4>
                          <i class="icon-bg" data-feather="file"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-warning">
                          <app-feather-icons [icon]="'award'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0">Payment Received Issues</span>
                          <h4 class="mb-0 counter">{{otherIssue?.payment_received_count}}</h4>
                          <i class="icon-bg" data-feather="award"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden card-shadow">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-teal">
                          <div>
                            <app-feather-icons [icon]="'folder'"></app-feather-icons>
                          </div>
                        </div>
                        <div class="media-body"><span class="m-0">Document Delivered Issues</span>
                          <h4 class="mb-0 counter">{{otherIssue?.document_delivered_count}}</h4>
                          <i class="icon-bg" data-feather="folder"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

